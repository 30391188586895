import React                                         from 'react';
import { graphql }                                   from 'gatsby';
import { Link }                                      from 'gatsby';
import Img                                           from 'gatsby-image';
import { Col, Container, Row }                       from 'react-grid-system';
import { FaMapMarker, FaPhone, FaEnvelope, FaGlobe } from 'react-icons/lib/fa';
import styled                                        from 'styled-components';

import Carousel           from '../components/Carousel';
import Spacer             from '../components/Spacer';
import Expander           from '../components/Expander';
import Heading            from '../components/Heading';
import Wrapper            from '../components/Wrapper';
import GoogleMap          from '../components/GoogleMap';
import NetlifyContactForm from '../components/NetlifyContactForm';

import DefaultLayout from '../layouts/Default';

const List = styled.ul`
  list-style-type: none;
  margin: 0;
  li {
    margin: 0;
    padding: 8px 0;
    svg {
      margin-right: 10px;
    }
  }
`;

function KontaktPage(props) {
  const contact = props.data.contact.edges[0].node.contact;
  return (
    <DefaultLayout location={props.location}>
      <Carousel>
        {props.data.headerImages.edges.map((image) => (
          <Img key={image.node.name} fluid={image.node.childImageSharp.fluid} alt={image.node.name}/>
        ))}
      </Carousel>
      <Wrapper>
        <Spacer/>
        <Heading>Kontaktieren Sie uns</Heading>
        <Container fluid>
          <Row>
            <Col md={6}>
              <h3>Kontakt Info</h3>
              <List>
                <li>
                  <FaMapMarker/>
                  {contact.address.street} - {contact.address.place}
                </li>
                <li>
                  <FaPhone/>
                  {contact.contact.phone}
                </li>
                <li>
                  <FaEnvelope/>
                  {contact.contact.email}
                </li>
                <li>
                  <FaGlobe/>
                  {contact.contact.web}
                </li>
              </List>
            </Col>
            <Col md={6}>
              <NetlifyContactForm/>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <p>
                Ja, ich habe die <Link to='/datenschutz'>Datenschutzerklärung</Link> zur Kenntnis genommen und bin
                damit einverstanden, dass die von mir angegebenen Daten elektronisch erhoben und gespeichert werden.
                Meine Daten werden dabei nur streng zweckgebunden zur Bearbeitung und Beantwortung meiner Anfrage
                benutzt. Mit dem Absenden des Kontaktformulars erkläre ich mich mit der Verarbeitung einverstanden.
              </p>
            </Col>
          </Row>
        </Container>
        <Spacer/>
      </Wrapper>
      <Expander title={'So finden Sie uns'}>
        <GoogleMap
          location={contact.googleMaps.location}
          apiKey={contact.googleMaps.apiKey}/>
      </Expander>
      <Spacer/>
    </DefaultLayout>

  )
}

export default KontaktPage;

export const query = graphql`
  query KontaktPageQuery {
    headerImages: allFile(filter: {relativeDirectory: {eq: "headers/contact"}}) {
      edges {
        node {
          ...HeaderImages
        }
      }
    }
    contact: allConfigJson {
      edges {
        node {
          ...Contact
        }
      }
    }
  }
`;