import React           from 'react';
import PropTypes       from 'prop-types';
import { FaAngleDown, FaAngleUp } from 'react-icons/lib/fa';

import * as S from './styles';

class Expander extends React.Component {

  //TODO Make header and content styling more customizable

  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    }
  }

  static propTypes = {
    title: PropTypes.string.isRequired,
    headerBgColor: PropTypes.string,
    textColor: PropTypes.string,
  };

  toggleContent() {
    this.setState(prevState => ({
      expanded: !prevState.expanded,
    }))
  }

  render() {
    return (
      <section>
        <S.Header headerBgColor={this.props.headerBgColor} textColor={this.props.textColor} onClick={() => this.toggleContent()}>
          {this.props.title}
          {this.state.expanded ? <FaAngleUp/> : <FaAngleDown/>}
        </S.Header>
        <S.Content expanded={this.state.expanded}>{this.props.children}</S.Content>
      </section>
    );
  }
}

export default Expander;
