import styled from 'styled-components';

export const Header = styled.div`
  width: 100%;
  background-color: ${props => props.headerBgColor ? props.headerBgColor : props.theme.colors.brandColor};
  color: ${props => props.textColor ? props.textColor : props.theme.colors.baseColor};
  text-transform: uppercase;
  padding: 25px 0;
  text-align: center;
  font-weight: lighter;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;
  &:hover {
    background-color: #fff;
    color: ${props => props.theme.colors.brandColor};
  }
  svg {
    height: 2rem;
    width: 2rem;
    vertical-align: bottom !important;
    margin-left: 10px;
  }
`;

export const Content = styled.div`
  overflow: hidden;
  transition: max-height 0.3s;
  max-height: ${props => props.expanded ? '500px' : '0'}
`;