import styled  from 'styled-components';

export const Form = styled.form`
  .hidden {
    display: none;
  }
  label {
    display: block;
  }
  input {
    padding: 5px 10px;
    width: 100%;
    border: 1px solid rgb(204, 204, 204);
  }
  textarea {
    width: 100%;
    height: 130px;
    padding: 5px 10px;
    resize: none;
    border-color: rgb(204, 204, 204);
  }
`;
