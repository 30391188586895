import React                   from 'react';
import { push }                from 'gatsby';
import { Col, Container, Row } from 'react-grid-system';

import Button from '../Button';

import * as S from './styles';

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

class NetlifyContactForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state
      })
    })
      .then(() => push({
        pathname: form.getAttribute('action'),
        state: this.state,
      }))
      .catch(error => alert(error));
  };


  render() {
    return (
      <S.Form
        name='contact'
        method='POST'
        action='/thanks/'
        data-netlify='true'
        data-netlify-honeypot='bot-field'
        onSubmit={this.handleSubmit}>
        <div className='hidden'>
          <input type='hidden' name='form-name' value='contact'/>
          <label hidden>
            Don’t fill this out:{' '}
            <input name='bot-field' onChange={this.handleChange}/>
          </label>
        </div>
        <Container fluid>
          <Row>
            <Col md={6}>
              <label htmlFor='name'>Name</label>
              <input type='text' name='name' placeholder='Jon Doe' required onChange={this.handleChange}/>
            </Col>
            <Col md={6}>
              <label htmlFor='email'>E-Mail</label>
              <input type='email' name='email' placeholder='jon@doe.de' required onChange={this.handleChange}/>
            </Col>
          </Row>
          <Row style={{ marginTop: '15px' }}>
            <Col md={12}>
              <label htmlFor='message'>Nachricht</label>
              <textarea name='message' placeholder='Hallo...' required onChange={this.handleChange}/>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button type='submit'>Senden</Button>
            </Col>
          </Row>
        </Container>
      </S.Form>
    )
  }
}

export default NetlifyContactForm;
