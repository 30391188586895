import React                             from 'react';
import PropTypes                         from 'prop-types';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';

class GoogleMap extends React.Component {

  static propTypes = {
    apiKey: PropTypes.string.isRequired,
    location: PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired,
    }).isRequired,
    zoom: PropTypes.number.isRequired,
    height: PropTypes.string.isRequired,
  };

  static defaultProps = {
    zoom: 14,
    height: '500px',
  };

  render() {
    return (
      <Map google={this.props.google}
           zoom={this.props.zoom}
           initialCenter={this.props.location}
           containerStyle={{
             position: 'relative',
           }}
           style={{
             height: this.props.height,
             position: 'relative',
           }}
      >
        <Marker/>
      </Map>
    )
  }
}

export default GoogleApiWrapper(
  (props) => ({
      apiKey: props.apiKey,
      language: 'de',
    }
  ))(GoogleMap)

//TODO make language configurable